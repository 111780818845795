import {Card, Divider, Menu, Select, Space, Typography} from "antd";
import React, { useEffect } from 'react';
import { getResources, ReduxState, ResourceDefinition } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from "react-router-dom";
import { setViewMode } from "../../actions/uiActions";
// @ts-ignore
import logo from '../../assets/img/netorca.png';
import {
  faChartPie
} from '@fortawesome/free-solid-svg-icons';
import FaIcon from "../Icons/FaIcon";

const getViewModeOfResource = (currentResource: string, resources: ResourceDefinition[]) => {
  for (let resource of resources) {
    if (resource.name == currentResource) {
      return resource.options?.viewMode
    }
  }
  return null;
}

const renderResource = (resource: any) => {
  return (
    <Menu.Item key={resource.name}>
      <NavLink to={"/" + resource.name}>
        <span
          className="icon"
        >
          {resource.icon}
        </span>
        <span className="label" style={{ textTransform: 'capitalize' }}>{resource.options?.label || resource.name}</span>
      </NavLink>
    </Menu.Item>
  )
}
const SideNav = () => {
  const resources = useSelector(getResources);
  const dispatch = useDispatch();
  const { Option } = Select;
  const { SubMenu } = Menu
  const viewMode = localStorage.getItem('pov_preference') || useSelector((state: ReduxState) => state.ui.viewMode);
  const currentPath = useSelector((state: ReduxState) => state.router.location.pathname);
  const user = JSON.parse(localStorage.getItem('user') as string);

  useEffect(() => {
    const currentResource: string = currentPath.replace(/[\/\\]/, '').split('/')[0];
    const resourceViewMode = getViewModeOfResource(currentResource, resources)
    if (resourceViewMode && resourceViewMode != viewMode) {
      dispatch(setViewMode(resourceViewMode));
    }
  }, [currentPath, resources])

  let history = useHistory();
  const handleViewChange = (value: string) => {
    dispatch(setViewMode(value));
    localStorage.setItem('pov_preference', value);
    history.push('/');
  }

  return (
    <>
      <div className="">
        <a href='/#'><img src={logo} alt="" /></a>
      </div>
      <Select defaultValue="CONSUMER" value={viewMode} style={{ width: '180px', marginBottom: '30px' }} onChange={handleViewChange}>
        <Option value="CONSUMER">Consumer</Option>
        <Option value="SERVICE_OWNER">Service Owner</Option>
      </Select>
      <Menu theme="light" mode="inline">
        <Menu.Item key='dashboard'>
          <NavLink exact to='/'>
            <span className="icon">{FaIcon(faChartPie)}</span>
            <span className="label" style={{ textTransform: 'capitalize' }}>Dashboard</span>
          </NavLink>
        </Menu.Item>
        {
          resources.map(resource =>
          !resource.options?.belowFold &&
          (resource.options?.viewMode == viewMode || !resource.options?.viewMode) &&
          resource.options?.viewMode != false ?
              (renderResource(resource))
              :
              null
          )
        }
        <Divider />
        {
          resources.map(resource =>
          resource.options?.belowFold == true &&
          (resource.options?.viewMode == viewMode || !resource.options?.viewMode) &&
          resource.options?.viewMode != false ?
              (renderResource(resource))
              :
              null
          )
        }
        {
          user?.is_superuser &&
            <>
              <Divider />
              {resources.map((resource => resource.options?.viewMode==="superuser"?renderResource(resource):null))}
            </>
        }
      </Menu>
    </>
  )
}

export default SideNav;