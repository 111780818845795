import {Card, Typography, Timeline, Tooltip, Divider, Spin} from 'antd';
import React, {useState, useEffect} from 'react';
import {useQuery} from 'react-admin';
import {formatDateAndTime, formatDateAndTimeFromNow} from "@src/helpers/utils";

const { Text } = Typography;


const HighlightText = (text: string, keywords: any) => {
    if (!keywords || keywords.length === 0) return <p>{text}</p>
    const regex = new RegExp(`(${keywords.join("|")})`, "gi");
    const parts = text.split(regex);

    return (
        <>
            {
                parts.map((part, index) => keywords.some((keyword:string) =>
                    part.toLowerCase() === keyword.toLowerCase()) ? <Text code>{part}</Text> : part)
            }
        </>
    );
};


const renderMessage = (activity: any) => {
    const keywords = Object.values(activity?.extra_data)
    const roles = ["ADMIN", "GENERAL", "READ_ONLY", "SYSTEM"]
    keywords.push(...roles)
    keywords.push(activity.history_username)

    return <>
        {HighlightText(activity.message, keywords)}
        <Tooltip title={formatDateAndTime(activity.history_date)}>
            <Text type="secondary">{"  " + formatDateAndTimeFromNow(activity.history_date)}</Text>
        </Tooltip>
    </>
}


const TeamActivity = ({teamId}: {teamId: number}) => {
    const [activities, setActivities] = useState<any[]>([]);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const limit = 20;
    const spinnerElement = <Spin size="large" tip="Loading"><Card style={{height: "100px"}}></Card></Spin>

    const { data, loaded, error, loading } = useQuery({
        type: 'getAction',
        resource: "teams",
        payload: { id: teamId, verb: 'activity', filter: { limit: limit, offset: offset }}
    });

    useEffect(() => {
        if (loaded && data?.results) {
            setActivities((prev) => [...prev, ...data.results]);
            setHasMore(data.results.length === limit); // Check if there are more results to load
        }
    }, [data, loaded]);

    const loadMore = () => {
        if (!loading && hasMore) {
            setOffset((prevOffset) => prevOffset + limit);
        }
    };

    return (
        <Card
            bordered={false}
            bodyStyle={{ padding: '20px' }}
            className="criclebox tablespace mb-24"
            title="Team Activity"
        >
            {
                data?
                    <>
                        <Timeline>
                            {Object.values(activities).map((activity: any) => <Timeline.Item>{renderMessage(activity)}</Timeline.Item>)}
                        </Timeline>
                        {hasMore && (
                            <Divider plain>
                                <a onClick={loadMore}>{loading ? "Loading..." : "Load More"}</a>
                            </Divider>
                        )}
                    </>
                :
                    spinnerElement
            }
        </Card>
    )
}

export default TeamActivity;
